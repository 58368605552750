import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { Layout, MultiCheckbox, Parallax, DelayedLink } from '@common'
import { WPApi } from '@api'
import { OutboundLink, trackCustomEvent } from 'gatsby-plugin-google-analytics'

import {
	page__wrapper,
	page__quote,
	page__parallax,
	page__parallax__inner,
	page__buttons,
	page__button,
	page__button__alt,
	page__testimonial,
	page__list,
	page__grid,
	page__grid__row,
	page__selectors,
	page__selectors__item,
	page__selector__imgs,
	page__form,
} from './styles/page.module.scss'

const Inputs = ({
	email,
	onChangeEmail,
	githubLogin,
	onChangeGithub,
	send,
	imBusy,
	message,
}) => {
	if (imBusy) {
		return (
			<div className={page__form}>
				<h5>Trwa dopisywanie do listy mailingowej</h5>
			</div>
		)
	}

	if (message) {
		return (
			<div className={page__form}>
				<h3>{message}</h3>
			</div>
		)
	}
	return (
		<React.Fragment>
			<form className={page__form}>
				<input
					type='email'
					value={email}
					required={true}
					name={'email'}
					placeholder='Miejsce na Twój mail'
					onChange={(ev) => {
						onChangeEmail(ev.target.value)
					}}
				/>
				<div className={page__buttons}>
					<div className={`${page__button} ${page__button__alt}`}>
						<a
							disabled={imBusy}
							onClick={() => {
								trackCustomEvent({
									category: 'add-to-zoom',
									action: 'newsletter-click'
								})

								if (
									email.includes('@')
								) {
									send()
								}
							}}
						>
							Dopisz się do listy
						</a>
						<span>&gt;</span>
					</div>
				</div>
			</form>
		</React.Fragment>
	)
}

const Values = () => {
	return (
		<React.Fragment>
			<h1>
				Stań się <strong>Patronem inicjatywy</strong>, zyskując{' '}
				<strong>dożywotni dostęp do zbiorów premium</strong>
			</h1>
			<div className={page__parallax}>
				<Parallax height={400}>
					<div className={page__parallax__inner}>
						<h1>
							<strong>
								7 solidnych powodów, dlaczego warto
							</strong>{' '}
							subskrybować w swojej programistycznej kolekcji moje
							zbiory zadań
						</h1>
					</div>
				</Parallax>
			</div>
			<ul>
				<li>
					<span>1</span>
					<h4>
						Każdy zbiór to już{' '}
						<strong>ponad 200h intensywnej pracy</strong> głową, a
						regularnie dorzucam do nich kolejne zadania bądź
						inspirację. Zyskasz{' '}
						<strong>
							dożywotnią subskrybcję na repozytorium
						</strong>{' '}
						ze zbiorem
					</h4>
				</li>

				<li>
					<span>2</span>
					<h4>
						Ambitne zadania wymagające{' '}
						<strong>zdobycia nowej wiedzy</strong>, bo ciągły rozwój
						i kodowanie jako rzemiosło to podstawa w szybko
						zmieniającej się branży IT
					</h4>
				</li>
				<li>
					<span>3</span>
					<h4>
						Zadania są pisane w JS i w React, ale{' '}
						<strong>większość problemów jest uniwersalna</strong> i
						można podjąć się rozwiązania za pomocą Angulara czy Vue.
						Większość zadania z pisania logiki z JS z powodzeniem
						odtworzysz w Pythonie czy PHPie
					</h4>
				</li>
				<li>
					<span>3</span>
					<h4>
						<strong>Idealne ćwiczenia praktyczne</strong> dla osób
						po kursach i bootcampach, a także tych co już ubiegają
						się o pierwszą pracę. Kurs/bootcamp to dopiero początek,
						nieważne co powiedzieli na kursie
					</h4>
				</li>
				<li>
					<span>4</span>
					<h4>
						Wiele <strong>z życia i pracy wziętych</strong>{' '}
						problemów do rozwiązania. Zobaczysz jak wyglądają taski
						frontendowca
					</h4>
				</li>
				<li>
					<span>5</span>
					<h4>
						W zbiorach są inspiracje na{' '}
						<strong>rozbudowę portfolio</strong> o nowe, ciekawe
						projekty. Aby się wyróżnić na rynku i mieć o czym
						rozmawiać z HRem i technicznymi musisz mieć ze 2
						ambitniejsze projekty, o których opowiesz na rozmowie
						rekrutacyjnej
					</h4>
				</li>
				<li>
					<span>6</span>
					<h4>
						Wiele <strong>z życia i pracy wziętych</strong>{' '}
						problemów do rozwiązania. Zobacz jak wyglądają taski
						frontendowca
					</h4>
				</li>
				<li>
					<span>7</span>
					<h4>
						<strong>Wspierasz moją inicjatywę</strong> dzięki czemu
						mogę poświecać maksimum czas na{' '}
						<strong>
							TUNING polskich niedoświadczonych programistów
							Javascript
						</strong>, aby mogli zdobywać rynki na całym świecie
					</h4>
				</li>
			</ul>
		</React.Fragment>
	)
}

const CodeLiveReview = ({ pageContext, location }) => {
	const [email, setEmail] = useState('')
	const [imBusy, setBusy] = useState(false)
	const [message, setMessage] = useState(false)

	useEffect(
		() => {
			if (message) {
				const timer = setTimeout(() => {
					setMessage(false)
					clearTimeout(timer)
				}, 3000)
			}
		},
		[message]
	)

	return (
		<Layout location={location} page={pageContext.page}>
			<div className={page__wrapper}>
				<h1>
					<strong>8 maja 2020 roku</strong> skończyły się walki o
					miejsce w <strong>
						Legionie Gladiatorów Javascriptu.
					</strong>{' '}
					Kolejna zmagania za 2 miesiące
				</h1>
				<h3>
					Jeśli Ci się nie udało lub nie wiedziałeś o inicjatywie, to{' '}
					<strong>spróbuj w następnej turze</strong>
				</h3>
				<h1>
				<strong>Co tydzień</strong> Gladiatorzy będą wykonywać{' '}
				<strong>3 zadania z 3 zbiorów</strong>
			</h1>
				<h1>
					Od <strong>połowy maja 2020 roku</strong>, przez najbliższe
					2 miesiące będziesz mógł/mogła oglądać regularne,
					cotygodniowe <strong>live Code Review</strong> zadań
					wykonanych przez Gladiatorów
				</h1>
				<h2>jest tylko <strong>80 miejsc</strong></h2>
				<h2>
					Dopisz się do listy, która będzie otrzymywać na maila linki
					do Zooma
				</h2>
				<Inputs
					{...{
						email,
					}}
					onChangeEmail={setEmail}
					imBusy={imBusy}
					message={message}
					send={() => {
						const data = {
							email,
						}
						setBusy(true)
						WPApi.addToZoomMail(data)
							.then((data) => {
								setBusy(false)
								setMessage(data.message)
								setEmail('')
							})
							.catch((error) => {
								setBusy(false)
								setMessage(error.message)
							})
					}}
				/>
				<h1>aby ćwiczyć ramię w ramię z Gladiatorami</h1>
				<h2>
					<strong>możesz wesprzeć moją inicjatywę</strong> i kupić te
					same zbiory dla siebie, aby <strong>razem z Gladiatorami</strong> robić co
					tydzień zadania i korzystać jeszcze bardziej na Live Code Review
				</h2>
				<div className={page__buttons}>
					<div className={`${page__button}`}>
						<span>&gt;</span>
						<DelayedLink to={'/pricing/'}>
							Kup zbiory Gladiatorów Javascriptu
						</DelayedLink>
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default CodeLiveReview
